import { useUserAgant } from '../useUserAgant';
import { useState, useEffect } from 'react';

type OSType = 'ios' | 'android' | 'PC';

/**
 * 使用しているデバイスが以下のいずれかを判定する
 * - ios, android, PC
 */
export const useDevice = (): OSType => {
  const ua = useUserAgant();
  const [os, setOS] = useState<OSType>('PC');

  useEffect(() => {
    switch (ua && ua.os.name) {
      case 'iOS':
        setOS('ios');
        break;
      case 'Android':
        setOS('android');
        break;
      default:
        setOS('PC');
        break;
    }
  }, [ua]);

  return os;
};
