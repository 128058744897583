// ref: https://github.com/usereact/use-user-agent

import * as UAParser from 'ua-parser-js';

export interface UA {
  os?: IUAParser.IOS;
  browser?: IUAParser.IBrowser;
}

/**
 * UserAgantを判定
 */
export const useUserAgant = () => {
  const uaParser = new UAParser.UAParser();
  return {
    os: uaParser.getOS(),
    browser: uaParser.getBrowser()
  };
};
