import React from 'react';
import BaseLayoutTemplate from '../components/environments/BaseLayoutTemplate';
import ReactHelmet from 'react-helmet';
import { useDevice } from '../customHooks/useDevice';

const QRCodePage = () => {
  const os = useDevice();
  const isiOS = os === 'ios';
  const isAndroid = os === 'android';

  if (isiOS) {
    return (
      <BaseLayoutTemplate>
        <ReactHelmet htmlAttributes={{ lang: 'ja' }}>
          <meta
            http-equiv='refresh'
            content='0;URL=https://apps.apple.com/jp/app/croccha/id1420803859?ls=1'
          />
          <meta name='robots' content='noindex' />
        </ReactHelmet>
        <span>app storeに遷移しています...</span>
      </BaseLayoutTemplate>
    );
  }

  if (isAndroid) {
    return (
      <BaseLayoutTemplate>
        <ReactHelmet htmlAttributes={{ lang: 'ja' }}>
          <meta
            http-equiv='refresh'
            content='0;URL=https://play.google.com/store/apps/details?id=com.croccha.app'
          />
          <meta name='robots' content='noindex' />
        </ReactHelmet>

        <span>google playに遷移しています...</span>
      </BaseLayoutTemplate>
    );
  }

  return (
    <BaseLayoutTemplate>
      <ReactHelmet htmlAttributes={{ lang: 'ja' }}>
        <meta
          http-equiv='refresh'
          content='0;URL=https://apps.apple.com/jp/app/croccha/id1420803859?ls=1'
        />
        <meta name='robots' content='noindex' />
      </ReactHelmet>
      <span>app storeに遷移しています...</span>
    </BaseLayoutTemplate>
  );
};

export default QRCodePage;
